import React, { PropsWithChildren } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import AuthProvider from './AuthProvider';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Layout from './components/Layout';
import Login from './pages/Login';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/TermsConditions';
import LandingPage from './pages/LandingPage';

function App() {
	return (
		<ChakraProvider theme={theme}>
			{/* <AuthProviderRedirectCallback redirectURL={window.location.origin}> */}
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<LandingPage />} />
					<Route path="login" element={<Login />} />
					<Route path="privacy-policy" element={<Privacy />} />
					<Route
						path="terms-and-conditions"
						element={<TermsConditions />}
					/>
				</Route>
			</Routes>
			{/* </AuthProviderRedirectCallback> */}
		</ChakraProvider>
	);
}

export default App;
