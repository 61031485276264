import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import App from './App';
import { theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StoreProvider store={store}>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</StoreProvider>
);
