import { createSlice } from '@reduxjs/toolkit';

export const userInitialState = {
	username: undefined,
	isAuthenticated: false,
	waitlisted: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		updateUser: (state, action) => {
			state.username = action.payload.username;
			state.isAuthenticated = true;
			state.slug = action.payload.slug;
			state.first_name = action.payload.first_name;
			state.last_name = action.payload.last_name;
			state.email = action.payload.email;
			state.waitlisted = action.payload.waitlisted;
		},
		updateAuthenticationState: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		resetUser: (state) => {
			state.username = undefined;
			state.isAuthenticated = false;
			state.slug = undefined;
			state.first_name = undefined;
			state.last_name = undefined;
			state.email = undefined;
			state.waitlisted = false;
		},
	},
});

export const { updateUser, resetUser, updateAuthenticationState } =
	userSlice.actions;

export const selectUser = (state) => state.user;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;

export default userSlice.reducer;
