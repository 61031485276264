import React, { useContext, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Center,
	Divider,
	HStack,
	Heading,
	SimpleGrid,
	Text,
	VStack,
	Image,
	Box,
	useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hook';
import { selectUser, updateUser } from '../reducers/user/userSlice';
import { AuthContext } from '../AuthProvider';
import SocialAuth from '../components/SocialAuth';
import ImageUploader from '../components/ImageUploader';
import { FaPlus } from 'react-icons/fa';
import {
	ReactCompareSlider,
	ReactCompareSliderImage,
	ReactCompareSliderHandle,
} from 'react-compare-slider';
import { login as loginCall, getUser, joinWaitlist } from '../api/calls';
import { showMessages } from '../utils/messages';

const LandingPage = () => {
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const handleJoinWaitlist = () => {
		joinWaitlist(user.slug)
			.then(async (response) => {
				if (response.status === 200) {
					console.log(response.data);
					dispatch(updateUser(response.data['data']));
					showMessages(toast, response.data['messages']);
					// window.location.reload();
				}
			})
			.catch((error) => {
				showMessages(toast, error.response.data['messages']);
			});
	};

	useEffect(() => {
		console.log(user);
	}, [user]);

	return (
		<>
			{/* beautiful single page landing page with a title */}
			<Center p={2} pt={12}>
				<VStack spacing={6}>
					<Heading
						size="lg"
						color="blue.600"
						title="remove blur from your images"
					>
						remove blur from your images
					</Heading>

					{user.isAuthenticated ? (
						<>
							{user.waitlisted ? (
								<>
									<Text fontSize="md" color="purple">
										you are in the waitlist. we will notify
										you when the product is released
									</Text>
								</>
							) : (
								<>
									<Text fontSize="md" color="purple">
										releasing soon!
									</Text>
									<Button
										size="md"
										colorScheme="blue"
										leftIcon={<FaPlus />}
										onClick={handleJoinWaitlist}
									>
										join waitlist to get notified
									</Button>
									{/* <ImageUploader /> */}
								</>
							)}
						</>
					) : (
						<>
							<Box boxSize={['sm', 'md']}>
								<Center>
									{/* <Image
										src="./home1.png"
										alt="unblurred image of a person"
										borderRadius="24px"
									/> */}
									<ReactCompareSlider
										handle={
											<ReactCompareSliderHandle
												buttonStyle={{
													background: 'white',
													width: '40px',
													height: '40px',
												}}
											/>
										}
										itemOne={
											<ReactCompareSliderImage
												src="./blurred1.jpg"
												alt="Image one"
												style={{
													borderRadius: '24px',
												}}
											/>
										}
										itemTwo={
											<ReactCompareSliderImage
												src="./blurred1_after.jpg"
												alt="Image two"
												style={{
													borderRadius: '24px',
												}}
											/>
										}
									/>
								</Center>
							</Box>
							<Text color="blue.800" as="b">
								Sign in now to unblur your images
							</Text>
							<SocialAuth />
						</>
					)}
				</VStack>
			</Center>
		</>
	);
};

export default LandingPage;
