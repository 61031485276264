import { toProperCase } from './string';

export const showMessages = (toast, messages) => {
	if (messages === undefined || messages.length <= 0) return;
	messages.forEach((message) => {
		toast({
			// title: toProperCase(message[1]),
			description: message[1],
			status: message[0],
			duration: 3000,
			isClosable: true,
		});
	});
};
