import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../AuthProvider';
import { useAppSelector, useAppDispatch } from '../hook';
import { selectUser, updateUser } from '../reducers/user/userSlice';
import { useNavigate } from 'react-router-dom';
import {
	Center,
	Heading,
	SimpleGrid,
	VStack,
	FormControl,
	Input,
	Button,
	Text,
	Link,
	InputGroup,
	InputRightElement,
	HStack,
	Divider,
	ButtonGroup,
	Stack,
	IconButton,
	Box,
	AbsoluteCenter,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { FaEye, FaEyeSlash, FaGithub } from 'react-icons/fa';
import SocialAuth from '../components/SocialAuth';
import { login as loginCall, getUser } from '../api/calls';

const Login = () => {
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
	const [isUsernameError, setIsUsernameError] = React.useState(false);
	const [isPasswordError, setIsPasswordError] = React.useState(false);
	const [isLoggingIn, setIsLoggingIn] = React.useState(false);

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	const validTextInput = (text) => {
		if (
			text.length <= 0 ||
			text === '' ||
			text === ' ' ||
			text === null ||
			text === undefined
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleLogin = async () => {
		if (validTextInput(username) && validTextInput(password)) {
			if (password.length < 6) {
				setIsPasswordError(true);
				return;
			}
			setIsLoggingIn(true);
			let formData = new FormData();
			formData.append('email', username.toLowerCase());
			formData.append('password', password);
			loginCall(formData)
				.then(async (response) => {
					if (response.status === 200) {
						dispatch(updateUser(response.data['data']['user']));
						toast({
							title: 'Logged in successfully',
							status: 'success',
							duration: 5000,
							isClosable: true,
						});
						navigate('/');
					}
				})
				.catch(async () => {
					toast({
						title: 'Error logging in',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				});
			setIsLoggingIn(false);
		} else {
			if (!validTextInput(username)) {
				setIsUsernameError(true);
			}
			if (!validTextInput(password)) {
				setIsPasswordError(true);
			}
		}
	};

	useEffect(() => {}, [user]);

	return (
		<>
			<Center>
				<SimpleGrid spacing={10} mb={36}>
					<VStack spacing={10}>
						<Heading as="h3" size="lg" textAlign="center">
							Login
						</Heading>
						<SocialAuth />
						<Text>or</Text>

						<FormControl
							isInvalid={isUsernameError}
							isRequired={true}
						>
							<Input
								type="text"
								value={username}
								placeholder="Username"
								name="username"
								onChange={(e) => setUsername(e.target.value)}
								variant="outline"
								isDisabled={isLoggingIn}
							/>
						</FormControl>
						<FormControl
							isInvalid={isPasswordError}
							isRequired={true}
						>
							<InputGroup>
								<Input
									type={
										isPasswordVisible ? 'text' : 'password'
									}
									value={password}
									placeholder="Password"
									name="password"
									onChange={(e) =>
										setPassword(e.target.value)
									}
									variant="outline"
									isDisabled={isLoggingIn}
								/>
								<InputRightElement
									children={
										<Button
											onClick={togglePasswordVisibility}
											variant="ghost"
											size="sm"
										>
											{isPasswordVisible ? (
												<FaEyeSlash />
											) : (
												<FaEye />
											)}
										</Button>
									}
								/>
							</InputGroup>
						</FormControl>
						<Button
							backgroundColor="teal"
							color="white"
							size="md"
							width={300}
							onClick={handleLogin}
							isLoading={isLoggingIn}
							loadingText="Login"
						>
							Login
						</Button>
					</VStack>
				</SimpleGrid>
			</Center>
		</>
	);
};

export default Login;
