import React, { useEffect } from 'react';
import NavBar from './NavBar';

import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hook';
import { selectUser, updateUser } from '../reducers/user/userSlice';
import { login as loginCall, getUser } from '../api/calls';

const Layout = () => {
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();

	const retrieveUser = async () => {
		getUser()
			.then(async (response) => {
				if (response.status === 200) {
					if (
						response.data['data']['authenticated'] &&
						response.data['data']['user'] !== null
					) {
						console.log(response.data['data']['user']);
						await Promise.resolve(
							dispatch(updateUser(response.data['data']['user']))
						);
						if (window.location.pathname === '/login') {
							navigate('/');
						}
					}
				}
			})
			.catch((error) => {
				// pass
			});
	};

	useEffect(() => {
		if (user.username === undefined || !user.isAuthenticated) {
			retrieveUser();
		}
	}, [user]);

	return (
		<>
			<NavBar />
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
