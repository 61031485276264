import React, { useContext } from 'react';
import {
	Avatar,
	AvatarBadge,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Flex,
	Heading,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
import ColorModeSwitcher from './ColorModeSwitcher';
import { AuthContext } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hook';
import { selectUser, resetUser } from '../reducers/user/userSlice';
import { logout as logoutCall } from '../api/calls';
import { FaSignOutAlt } from 'react-icons/fa';

const NavBar = () => {
	const colorMode = useColorModeValue('light', 'dark');
	const navigate = useNavigate();
	const toast = useToast();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();

	const handleLogin = () => {
		navigate('login');
	};

	const handleLogout = async () => {
		logoutCall()
			.then(async (response) => {
				if (response.status === 200) {
					toast({
						title: 'Logged out successfully',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					dispatch(resetUser());
				}
			})
			.catch(async () => {
				toast({
					title: 'Error logging out',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
	};

	return (
		<Flex
			minWidth="max-content"
			alignItems="center"
			gap="2"
			p={2}
			background={colorMode === 'light' ? 'white' : 'gray.800'}
		>
			<Box ml={2}>
				<Link to="/">
					<Heading size="md">removeblur</Heading>
				</Link>
			</Box>
			<Spacer />
			{user.isAuthenticated ? (
				// <Menu>
				// 	{user.first_name !== '' && user.last_name !== '' ? (
				// 		<Avatar
				// 			size="sm"
				// 			name={user.first_name + ' ' + user.last_name}
				// 		>
				// 			<AvatarBadge
				// 				boxSize="1.25em"
				// 				bg={
				// 					user.isAuthenticated
				// 						? 'green.500'
				// 						: 'red.500'
				// 				}
				// 			/>
				// 		</Avatar>
				// 	) : (
				// 		<Avatar size="sm" name={user.email || ''}>
				// 			<AvatarBadge
				// 				boxSize="1.25em"
				// 				bg={
				// 					user.isAuthenticated
				// 						? 'green.500'
				// 						: 'red.500'
				// 				}
				// 			/>
				// 		</Avatar>
				// 	)}
				// 	<MenuList>
				// 		<MenuItem onClick={handleLogout}>Logout</MenuItem>
				// 	</MenuList>
				// </Menu>
				<Button
					rightIcon={<FaSignOutAlt />}
					size="xs"
					onClick={handleLogout}
				>
					Log out
				</Button>
			) : (
				<ButtonGroup gap="2">
					{/* TODO: Update size based on device */}
					<Button
						colorScheme="purple"
						size="xs"
						onClick={handleLogin}
					>
						Log in
					</Button>
				</ButtonGroup>
			)}
			<ColorModeSwitcher />
		</Flex>
	);
};

export default NavBar;
