import {
	Box,
	Heading,
	Link,
	Text,
	ListItem,
	UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

const Privacy = () => {
	return (
		<>
			<Box padding={5}>
				<Heading textAlign="center">Privacy Policy</Heading>
				<p>
					<Heading as="h4" size="md">
						Introduction
					</Heading>
					At removeblur.com, we respect your privacy. This policy
					details how we gather, utilize, and safeguard your personal
					data when you access our website. By using our services, you
					consent to the practices outlined in this policy.
					<Heading as="h4" size="md">
						Information We Collect
					</Heading>
					<UnorderedList>
						<ListItem>
							Images: You provide images to be processed by our
							unblurring service.
						</ListItem>
						<ListItem>
							Account Information: When you create a custom login
							or use third-party logins (e.g., Google OAuth2), we
							may collect information such as your name, email
							address, and profile picture.
						</ListItem>
						<ListItem>
							Technical Data: We automatically collect data
							including your IP address, browser type, operating
							system, device information, and your usage patterns
							on our website.
						</ListItem>
						<ListItem>
							Cookies: We use cookies and similar technologies to
							enhance your experience and analyze website traffic.
						</ListItem>
						Information Use
					</UnorderedList>
					We use your information for these purposes:
					<UnorderedList>
						<ListItem>
							Image Processing: Processing and unblurring the
							images you upload.
						</ListItem>
						<ListItem>
							Account Management: Creating and managing your
							account.
						</ListItem>
						<ListItem>
							Service Improvement: Analyzing data to understand
							usage patterns and improve our website.
						</ListItem>
						<ListItem>
							Communication: Sending you service-related updates,
							if you choose to receive them.
						</ListItem>
						<ListItem>
							Protecting our website and users from unauthorized
							access or harmful activity.
						</ListItem>
					</UnorderedList>
					<Heading as="h4" size="md">
						Information Sharing Rights
					</Heading>
					<UnorderedList>
						We will not sell or rent your personal information to
						third parties. We may store/share information in these
						limited circumstances:
						<ListItem>
							Service Providers: With trusted partners who help us
							operate the website (e.g., image processing
							services, cloud storage providers).
						</ListItem>
						<ListItem>
							Legal Obligations: To comply with laws, regulations,
							or valid legal processes.
						</ListItem>
						<ListItem>
							Business Changes: In the event of a merger,
							acquisition, or sale of assets.
						</ListItem>
					</UnorderedList>
					<Heading as="h4" size="md">
						Information Security
					</Heading>
					We prioritize the protection of your data. We employ
					appropriate technical and organizational security measures
					to safeguard your information from unauthorized access, use,
					alteration, or disclosure.
					<Heading as="h4" size="md">
						Your Rights
					</Heading>
					You possess the following rights regarding your data:
					<UnorderedList>
						<ListItem>
							Access and Portability: You can request a copy of
							your personal data we hold.
						</ListItem>
						<ListItem>
							Rectification: You can request corrections to
							inaccurate or incomplete data.
						</ListItem>
						<ListItem>
							Erasure: In certain circumstances, you can request
							deletion of your data.
						</ListItem>
						<ListItem>
							Restriction of Processing: You can request
							limitations on how we use your data.
						</ListItem>
						<ListItem>
							Withdrawal of Consent: If you've provided consent,
							you have the right to withdraw it.
						</ListItem>
					</UnorderedList>
					<Heading as="h4" size="md">
						Children's Privacy
					</Heading>
					Our website is open to users of all ages. If we discover
					that a child under the age applicable to your jurisdiction
					has provided personal information without parental consent,
					we will take steps to promptly delete that information.
					<Heading as="h4" size="md">
						International Transfers
					</Heading>
					Your information may be processed and stored outside your
					country of residence, where data protection laws might
					differ. We implement safeguards to ensure adequate
					protection when transferring your data internationally.
					<Heading as="h4" size="md">
						Changes to Privacy Policy
					</Heading>
					We reserve the right to modify this policy at any time.
					Updates will be posted on our website with the effective
					date indicated. Your continued use of our services following
					changes signifies your acceptance.
					<Heading as="h4" size="md">
						Contact Us
					</Heading>
					For any privacy-related questions or concerns, please
					contact us at: contact@removeblur.com
					<br />
					<p>
						<strong>Effective Date: March 4th, 2024</strong>
					</p>
				</p>
			</Box>
		</>
	);
};

export default Privacy;
