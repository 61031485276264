import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

if (document.cookie.match(/csrftoken=(\S+)/)?.length > 0) {
	axios.defaults.headers.common['X-CSRFTOKEN'] =
		document.cookie.match(/csrftoken=(\S+)/)[1];
}

const api_client = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const login = async (formData) => {
	return await api_client.post('/auth/login/', formData, {
		withCredentials: true,
	});
};

export const logout = async () => {
	return await api_client.post(
		'/auth/logout/',
		{},
		{
			withCredentials: true,
		}
	);
};

export const register = async (formData) => {
	return await api_client.post('/auth/register/', formData, {
		withCredentials: true,
	});
};

export const getUser = async () => {
	return await api_client.get('/auth/user/', { withCredentials: true });
};

export const joinWaitlist = async (slug) => {
	return await api_client.post(
		'/users/' + slug + '/join-waitlist/',
		{},
		{
			withCredentials: true,
		}
	);
};
