import {
	Box,
	ButtonGroup,
	Center,
	Container,
	Flex,
	HStack,
	Heading,
	IconButton,
	Link,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { FaGithub } from 'react-icons/fa';

const Footer = () => {
	const { colorMode } = useColorMode();
	return (
		<>
			<Container
				as="footer"
				role="contentinfo"
				py={{ base: '12', md: '16' }}
				backgroundColor={colorMode === 'light' ? 'gray.50' : 'gray.700'}
				mt={12}
				maxW={{ base: '100%' }}
			>
				<Stack spacing={{ base: '4', md: '5' }}>
					<Stack
						justify="space-between"
						direction="row"
						align="center"
					>
						<Heading size="md" fontWeight="semibold">
							removeblur
						</Heading>
					</Stack>
					<HStack>
						<Link
							textDecoration={'underline'}
							href="/privacy-policy"
						>
							privacy
						</Link>
						<Link
							textDecoration={'underline'}
							href="/terms-and-conditions"
						>
							terms & conditions
						</Link>
					</HStack>
					<Text fontSize="sm" color="fg.subtle">
						&copy; {new Date().getFullYear()} removeblur. All rights
						reserved.
					</Text>
				</Stack>
			</Container>
		</>
	);
};

export default Footer;
