import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import React from 'react';

const TermsConditions = () => {
	return (
		<>
			<Box padding={5}>
				<Heading textAlign="center">Terms and conditions</Heading>
				<p>
					Welcome to removeblur.com. By using our website and
					services, you agree to be bound by these Terms and
					Conditions. Please read them carefully.
				</p>

				<Heading as="h4" size="md">
					1. Scope of Service
				</Heading>
				<UnorderedList>
					<ListItem>
						We provide image processing services to unblur images
						uploaded by users.
					</ListItem>
					<ListItem>
						We strive to provide the best possible service but
						cannot guarantee perfect results on every image.
					</ListItem>
				</UnorderedList>

				<Heading as="h4" size="md">
					2. User Accounts
				</Heading>
				<UnorderedList>
					<ListItem>
						You have the option to create a custom account or use
						third-party logins (like Google OAuth2).
					</ListItem>
					<ListItem>
						You are responsible for the security of your login
						credentials.
					</ListItem>
					<ListItem>
						We reserve the right to suspend or terminate accounts at
						our discretion, particularly in case of violations of
						these Terms.
					</ListItem>
				</UnorderedList>

				<Heading as="h4" size="md">
					3. Intellectual Property
				</Heading>
				<UnorderedList>
					<ListItem>
						You retain ownership of the original images you upload.
					</ListItem>
					<ListItem>
						You grant us a license to process and temporarily store
						your images for the sole purpose of providing our
						unblurring service.
					</ListItem>
					<ListItem>
						The removeblur.com website, its design, and content are
						protected by intellectual property rights. You may not
						use them without express permission.
					</ListItem>
				</UnorderedList>

				<Heading as="h4" size="md">
					4. Acceptable Use
				</Heading>
				<UnorderedList>
					<ListItem>
						You agree not to use the website for any unlawful or
						harmful purposes.
					</ListItem>
					<ListItem>
						Do not upload images that are offensive, violate others'
						rights, or contain copyrighted material that you do not
						own.
					</ListItem>
					<ListItem>
						You are responsible for any content you upload and its
						potential consequences.
					</ListItem>
				</UnorderedList>

				<Heading as="h4" size="md">
					5. No Warranties
				</Heading>
				<p>
					Our service is provided "as is" without warranties of any
					kind. We do not guarantee specific results or that the
					service will be error-free.
				</p>

				<Heading as="h4" size="md">
					6. Limitation of Liability
				</Heading>
				<p>
					We shall not be liable for any damages arising from your use
					of the website, including indirect or consequential damages.
				</p>

				<Heading as="h4" size="md">
					7. Indemnification
				</Heading>
				<p>
					You agree to indemnify and hold us harmless from any claims
					or damages arising from your breach of these Terms or your
					use of the website.
				</p>

				<Heading as="h4" size="md">
					8. Termination
				</Heading>
				<p>
					We may terminate or suspend your access to the website at
					any time, without prior notice or liability, for any reason,
					including violation of these Terms.
				</p>

				<Heading as="h4" size="md">
					9. Changes to Terms
				</Heading>
				<p>
					We may update these Terms and Conditions from time to time.
					We'll post changes with the effective date. Your continued
					use signifies acceptance.
				</p>

				<Heading as="h4" size="md">
					10. Governing Law
				</Heading>
				<p>
					These Terms shall be governed and interpreted in accordance
					with the laws of Karnataka, India.
				</p>

				<Heading as="h4" size="md">
					11. Dispute Resolution
				</Heading>
				<p>
					Any disputes shall be resolved through courts located in
					Karnataka, India.
				</p>

				<Heading as="h4" size="md">
					12. Contact Information
				</Heading>
				<p>
					If you have any questions about these Terms, please contact
					us at contact@removeblur.com.
				</p>

				<p>
					<strong>Effective Date: March 4th, 2024</strong>
				</p>
			</Box>
		</>
	);
};

export default TermsConditions;
